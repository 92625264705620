



































import { Vue, Component, Ref, PropSync, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { tag } from '@/utils/options'
import _ from 'lodash'
import { actionTypes } from '@/store'

type FormData = { visible: string[]; editable: string[] }
type Tag = AnyObj

enum Authority {
  DELETED = 0,
  VISIBLE = 5,
  EDITABLE = 10
}

@Component
export default class TagDialog extends Vue {
  @PropSync('visible') syncedVisible: boolean
  @Prop() accountId: string

  @Ref() form: ElForm

  initialTags: Tag[] = []
  formData: FormData = { visible: [], editable: [] }
  formLoading = false
  loading = false

  tagTypes = tag.types

  get tags(): Tag[] {
    return this.$store.state.tags
  }

  get tagOptions() {
    return tag.types.map(({ value, label }) => ({
      value,
      label,
      children: this.tags
        .filter(({ type }) => type === value)
        .map(({ id, name, authority }) => {
          const target = this.initialTags.find(t => t.id === id)

          return { value: id, label: name, disabled: target && target.authority > authority }
        })
    }))
  }

  get editableTagOptions() {
    const tags = this.tags.filter(({ id }) => this.formData.visible.includes(id))

    return tag.types.map(({ value, label }) => ({
      value,
      label,
      children: tags
        .filter(({ type }) => type === value)
        .map(({ id, name, authority }) => {
          return { value: id, label: name, disabled: authority < Authority.EDITABLE }
        })
    }))
  }

  mounted() {
    this.fetchTagsWithAccount()
  }

  async fetchTagsWithAccount() {
    this.formLoading = true

    await this.$store.dispatch(actionTypes.FETCH_TAGS)

    const { data } = await this.$api.base.getTagsWithAccount(this.accountId)

    this.formLoading = false

    if (data.code === 0) {
      const tags = data.data as Tag[]

      this.formData = {
        visible: tags.map(({ id }) => id),
        editable: tags.filter(({ authority }) => authority === 10).map(({ id }) => id)
      }
      this.initialTags = tags
    }
  }

  onVisibleTagsChange(value: string[]) {
    this.formData.editable = this.formData.editable.filter(i => value.includes(i))
  }

  close() {
    this.syncedVisible = false
    this.formData = { visible: [], editable: [] }
    this.initialTags = []
  }

  cancel() {
    this.close()
  }

  async confirm() {
    const bulk = (tagId: string, authority: Authority) => ({
      accountId: this.accountId,
      tagId,
      authority
    })

    // 借助 Map 降低频繁取值的时间复杂度
    const map = new Map<string, Authority>()

    // formData visible 移除 editable 部分，并将二者映射合并为单一列表
    const tags = _.without(this.formData.visible, ...this.formData.editable)
      .map(id => ({ id, authority: Authority.VISIBLE }))
      .concat(this.formData.editable.map(id => ({ id, authority: Authority.EDITABLE })))

    // 遍历当前选中的标签，将其及对应权限全部添加到 map 中
    tags.forEach(({ id, authority }) => {
      map.set(id, authority)
    })

    // 遍历初始标签列表，如果 id 对应 map 中的 key 不存在，证明标签绑定状态被移除，添加一条 authority 为 0 的记录；
    // 如果对应 key 存在，并且 authority 也相同，证明绑定状态未发生变动，移除该条记录；
    // 最终，map 中剩余的记录为：初始列表中存在结果中不存在（删除）、初始列表中不存在结果中存在（新增）、初始列表中存在结果中也存在，但权限发生变化（更新）。
    this.initialTags.map(({ id, authority }) => {
      if (map.has(id)) {
        if (map.get(id) === authority) {
          map.delete(id)
        }
      } else {
        map.set(id, Authority.DELETED)
      }
    })

    this.loading = true

    const { status } = await this.$api.mind.bindTagAccount({
      bulk: Array.from(map.entries()).map(([id, authority]) => bulk(id, authority))
    })

    this.loading = false

    if (status === 204) {
      this.close()
    }
  }
}
