



















































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import validator from '@/components/common/validator'
import Modal from '@/components/common/Modal'
import TagDialog from './TagDialog.vue'
import { judgeValue } from '@/utils'
import { variables } from '@/libs/theme'
import { ElForm } from 'element-ui/types/form'

@Component({ components: { TagDialog } })
export default class AccountEdit extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 'NEW' }) type: 'NEW' | 'EDIT'
  @Prop({ default: () => [] }) private areaList: any[]
  @Prop({ default: () => ({}) }) data: AnyObj
  @Prop({}) private getAccountList: Function

  @Ref() readonly formNode!: ElForm

  private checkedAreaName = ''
  private form: any = {
    id: 0,
    username: '',
    name: '',
    root: '',
    allowChild: false,
    enabled: true,
    mobile: ''
  }
  private loading = false
  private activeColor = variables.errorColor
  tagDialog = Modal(TagDialog)

  get rules() {
    return {
      name: [
        {
          required: true,
          validator: validator.checkName({
            num: 256,
            error1: '请输入昵称',
            error2: '昵称应在256字符内，请重新输入'
          }),
          trigger: 'blur'
        }
      ],
      username: [{ required: true, message: '请输入账户', trigger: 'blur' }],
      root: [{ required: true, message: '请选择所属区域', trigger: 'blur' }],
      mobile: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator: validator.tel({}), trigger: 'blur' }
      ]
    }
  }
  @Watch('visible')
  watchVisible() {
    if (!this.visible) {
      this.formNode.resetFields()
    }
  }

  mounted() {
    this.initialize()
  }

  deactivated() {
    this.visible = false
  }

  initialize() {
    if (this.type === 'NEW') {
      this.$nextTick(() => {
        this.form = {
          id: 0,
          username: '',
          name: '',
          root: '',
          allowChild: false,
          enabled: true,
          mobile: ''
        }
      })
    } else {
      const { id, username, name, allowChild, enabled, mobile } = this.data
      const root = this.data.root?.id ?? ''

      this.$nextTick(() => {
        this.form = {
          id,
          username,
          name,
          root,
          allowChild,
          enabled,
          mobile: mobile ? mobile.replace('+86', '') : null
        }
      })
    }
  }

  private hide() {
    this.visible = false
  }
  private submit() {
    this.formNode.validate(async (valid: boolean) => {
      if (valid) {
        if (this.type === 'NEW') {
          this.loading = true

          const { username, name, root, allowChild, mobile } = this.form
          const data: any = { username, name }
          if (allowChild) data.allowChild = allowChild
          if (root) data.root = root
          if (mobile) data.mobile = '+86' + mobile
          judgeValue(data)
          try {
            const resp = await this.$api.mind.addAccount(data)

            if (resp.data && resp.data.code === 0) {
              const msgs = [
                `您的账户登录地址为：${location.protocol + '//' + location.host}`,
                `您的账户名称为：${username}`,
                `您的密码为：${resp.data.data.password}`,
                `登陆后可在页面右上角账户处修改密码`
              ]

              this.hide()
              this.copyText(msgs.join('\n'))
              this.$message({
                message: '创建成功，账号密码已经复制到您的剪切板！',
                type: 'success',
                customClass: 'is-conspicuous'
              })
              this.showAccountMsg(resp.data.data)
              this.getAccountList()
            }
            this.loading = false
          } catch (err) {
            this.$message({ message: '新建失败', type: 'error' })
            this.loading = false
          }
        }

        if (this.type === 'EDIT') {
          this.loading = true

          const { id, name, root, allowChild, enabled, mobile } = this.form
          try {
            const resp = await this.$api.mind.updateAccount(id, {
              name,
              root,
              allowChild,
              enabled,
              mobile: mobile ? '+86' + mobile : null
            })

            if (resp.data && resp.data.code === 0) {
              this.hide()
              this.$message({ message: '编辑成功', type: 'success' })
              this.getAccountList()
              this.loading = false
            } else {
              this.loading = false
            }
          } catch (error) {
            this.$message({ message: '编辑失败', type: 'error' })
            this.loading = false
          }
        }
      }
    })
  }
  showAccountMsg(account: AnyObj) {
    this.$msgbox({
      message: (
        <div>
          <h2 class="title">账号创建成功！</h2>

          <div class="msgs">
            <p>您的账户登录地址为：{location.protocol + '//' + location.host}</p>
            <p>您的账户名称为：{account.username}</p>
            <p>您的密码为：{account.password}</p>
            <p>登陆后可在页面右上角账户处修改密码</p>
          </div>

          <p class="hint">
            该账号需要完成可见标签设置才能正常使用
            <br />
            请点击下方“设置可见标签”按钮进行相关设置
          </p>
        </div>
      ),
      center: true,
      showClose: false,
      showCancelButton: true,
      confirmButtonText: '设置可见标签',
      cancelButtonText: '暂时先不设置',
      customClass: 'account-message-box'
    })
      .then(() => {
        this.tagDialog.open({ accountId: account.id })
      })
      .catch(() => {})
  }

  copyText(text: string) {
    const parent = document.documentElement
    const textarea = document.createElement('textarea')
    parent.appendChild(textarea)
    textarea.value = text
    textarea.select()

    document.execCommand('copy')

    parent.removeChild(textarea)
  }
}
