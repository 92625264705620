




































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import moment from 'moment'
import pageTitle from '@/components/common/pageTitle.vue'
import pagination from '@/components/common/pagination/index.vue'
import Modal from '@/components/common/Modal'
import editDialog from './editDialog.vue'
import TagDialog from './TagDialog.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import { Deferred } from '@/utils/dom'
import { variables } from '@/libs/theme'
import store, { actionTypes } from '@/store'

const dialog = Modal(editDialog)

@Component({
  name: 'account',
  components: {
    pageTitle,
    pagination,
    InputSearch
  }
})
export default class Account extends Vue {
  @Ref() readonly paginationNode: pagination
  private shadow9Color = variables.shadow9Color
  private tableHeight: number | null = null
  private accountList: any[] = []
  private areaList: any[] = []
  private loading = false
  private noData = require('@/assets/images/dashboard/noData.png')
  private pageSize = 20
  private currentPage = 1
  private allAccountList: any[] = []
  private areaReady: AnyObj = Deferred()
  private tagDialog = Modal(TagDialog)

  async mounted() {
    this.getAccountList()
    this.$nextTick(() => {
      this.tableHeight = (this.$refs.tableContiner as HTMLDivElement).clientHeight
    })
    dialog.update({ getAccountList: this.getAccountList })
  }
  refresh() {
    this.paginationNode.pageNo = 1
    this.currentPage = 1
    this.getAccountList()
  }
  private async getAccountList() {
    this.loading = true
    try {
      const resp = await this.$api.mind.searchAccount()
      if (resp.data && resp.data.code === 0) {
        this.allAccountList = resp.data.data
        this.setCurrentPageData()
        this.paginationNode.init({ total: resp.data.data.length })
      }

      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }
  setCurrentPageData() {
    const begin = (this.currentPage - 1) * this.pageSize
    const end = this.currentPage * this.pageSize
    this.accountList = this.allAccountList.slice(begin, end)
  }
  private async getAreasList() {
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)

    if (areas) {
      const arrList = Array.from(areas)
      arrList.push(areas)
      this.areaList = arrList
      this.areaReady.resolve()
      return arrList
    }
  }
  private async getAreaName(id: string) {
    await this.areaReady
    const allArea = this.flatten(this.areaList)
    for (const area of allArea) {
      if (area.id === id) return area.name
    }
  }
  private flatten(data: any) {
    return data.reduce(
      (arr: any, { id, name, children = [] }: any) =>
        arr.concat([{ id, name }], this.flatten(children)),
      []
    )
  }
  private getStatusName(status: boolean) {
    return status ? '启用' : '禁用'
  }
  private getBadgeType(status: boolean) {
    return status ? 'enabled' : 'disabled'
  }
  private dateFormat(row: any, column: AnyObj) {
    const date = row[column.property]
    if (date === undefined) {
      return ''
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
  private pageChange(data: AnyObj) {
    this.pageSize = data.pageSize
    this.currentPage = data.pageNo
    this.setCurrentPageData()
  }
  async resetPassword({ id, username }: AnyObj) {
    this.$confirm(`此账号（${username}）密码将重置，是否确认重置？`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(async () => {
        const { data } = await this.$api.mind.resetPassword(id)
        if (data && data.code === 0) {
          this.showAccountMsg([`账户名：${username}`, `临时密码：${data.data.password}`])
        }
      })
      .catch(() => {})
  }
  showAccountMsg(msgs: any) {
    const h = this.$createElement
    this.$msgbox({
      title: '请点击按钮复制',
      message: h(
        'div',
        { attrs: { id: 'reset-password-msg' } },
        //@ts-ignore
        msgs.map((r: any) => h('p', null, r))
      ),
      showCancelButton: true,
      confirmButtonText: '复制',
      cancelButtonText: '取消',
      cancelButtonClass: 'message-cancel-btn',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          const parent = document.getElementById('reset-password-msg') as HTMLDivElement
          const textarea = document.createElement('textarea')
          parent.appendChild(textarea)
          textarea.value = msgs.join('\n')
          textarea.select()

          document.execCommand('copy')

          this.$message({
            message: '复制成功！',
            type: 'success'
          })

          parent.removeChild(textarea)

          done()
        } else {
          done()
        }
      }
    }).catch(() => {})
  }
  private clickAdd() {
    this.getAreasList()
    dialog.open({ type: 'NEW' })
  }
  private async clickEdit(data: AnyObj) {
    this.getAreasList()
    dialog.open({ type: 'EDIT', data })
  }

  clickBindTag({ id }: AnyObj) {
    this.tagDialog.open({ accountId: id })
  }

  private clickDelete({ id }: any) {
    this.$confirm('账户一经删除无法恢复，是否确定删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn',
      loading: true,
      onConfirm: async () => {
        this.loading = true
        const resp = await this.$api.mind.removeAccount(id)
        if (resp.status === 204) {
          this.$message({ message: '删除成功！', type: 'success' })
          this.refresh()
        }
        this.loading = false
      }
    }).catch(() => {})
  }
}
